import { appConfiguration } from '../../configuration/appConfiguration';

export const PumpNumber = () => {
  return (
    <div>
      <div
        style={{
          zIndex: 1,
          backgroundColor: '#e5ebf0',
          position: 'absolute',
          right: 0,
          top: 150,
          width: 65,
          height: 40,
        }}
      />
      <div
        style={{
          zIndex: 1,
          position: 'absolute',
          right: 0,
          top: -47,
          fontSize: 200,
          fontFamily: 'MANEuropeCondensed,"serif"',
          fontWeight: 600,
        }}
      >
        {appConfiguration.pumpNumber}
      </div>
    </div>
  );
};
