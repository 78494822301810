import { Address } from './Address';
import { PumpNumber } from './PumpNumber';

export const Header = () => {
  const renderLine = (marginTop: number, lineStrength: number) => {
    return (
      <div className={'display-flex position-absolute'} style={{ width: '90vw' }}>
        <div
          className={'width-100pct'}
          style={{
            border: `${lineStrength}px solid #2a3740`,
            marginTop,
          }}
        />
      </div>
    );
  };

  return (
    <div className="display-flex justify-content-between" style={{ width: '80%' }}>
      <Address />
      <PumpNumber />
      {renderLine(130, 1)}
      {renderLine(140, 3)}
    </div>
  );
};
