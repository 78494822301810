import { MechanicalDial } from '../MechanicalDial';

type Props = {
  liters: number;
  cost: number;
};

export const LitersAndCost = ({ liters, cost }: Props) => {
  const fontSize = 80;
  return (
    <div>
      <h1 data-testid="mechanical-dial-price" style={{ marginBottom: 35 }}>
        <MechanicalDial value={cost} integerDigits={5} decimalDigits={2} size={fontSize} />
        <span style={{ fontSize: fontSize - 10, paddingLeft: 10 }}>€</span>
      </h1>
      <h1 data-testid="mechanical-dial-liters">
        <span data-testid="mechanical-dial-liters-value">
          <MechanicalDial value={liters} integerDigits={5} decimalDigits={2} size={fontSize} />
        </span>
        <span style={{ fontSize: fontSize - 10, paddingLeft: 10, textTransform: 'capitalize' }}>Liter</span>
      </h1>
    </div>
  );
};
