import { ReactNode } from 'react';

const formatValue = (value: number, minIntegerDigits: number, decimalDigits?: number): string => {
  const valueString = decimalDigits === undefined ? value.toString() : value.toFixed(decimalDigits);
  const [integerPart, decimalPart] = valueString.split('.');
  const paddedInteger = integerPart.padStart(minIntegerDigits, '0');
  return decimalPart ? `${paddedInteger}.${decimalPart}` : paddedInteger;
};

type Props = {
  value: number;
  integerDigits?: number;
  decimalDigits?: number;
  size?: number;
};

export const MechanicalDial = ({ value, integerDigits = 0, decimalDigits, size = 60 }: Props) => {
  const valueString = formatValue(value, integerDigits, decimalDigits);
  const digits: ReactNode[] = [];

  for (let i = 0; i < valueString.length; i++) {
    const char = valueString.charAt(i);
    if (char === '.') {
      digits.push(
        <span key={i} style={{ fontSize: size - 10 }}>
          ,
        </span>
      );
    } else {
      digits.push(
        <span
          key={i}
          className="border border-width-2 rounded-large bg-white padding-x-4 margin-3"
          style={{ fontSize: size }}
        >
          {char}
        </span>
      );
    }
  }
  return digits;
};
