import { useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { PricePerLiter } from '../../components/FuelingPump/PricePerLiter';
import { FailureButton } from '../../components/FuelingPump/FailureButton';
import { LitersAndCost } from '../../components/FuelingPump/LitersAndCost';
import { StartStopButton } from '../../components/FuelingPump/StartStopButton';
import { appConfiguration } from '../../configuration/appConfiguration';
import { FuelingPumpFailureDialog } from './dialog/FuelingPumpFailureDialog';
import { Header } from '../../components/FuelingPump/Header';

const NET_PRICE_PER_LITER = 1.352;
const GROSS_PRICE_PER_LITER = parseFloat((NET_PRICE_PER_LITER * 1.19).toFixed(3));
const LITERS_PER_SECOND = 5.1;
const UPDATE_INTERVAL_MS = 200;
const fuelingServiceUri = appConfiguration.fuelingServiceUri;
const locationId = appConfiguration.locationId;
const pumpNumber = appConfiguration.pumpNumber;

export const FuelingPump = () => {
  const [isFueling, setIsFueling] = useState<boolean>(false);
  const [liters, setLiters] = useState<number>(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const cost = liters * GROSS_PRICE_PER_LITER;

  const handleStartFueling = () => {
    setIsFueling(true);
    setLiters(0);
    setTimerId(
      setInterval(() => {
        const increment = (LITERS_PER_SECOND * UPDATE_INTERVAL_MS) / 1000;
        setLiters((prev: number) => prev + increment);
      }, UPDATE_INTERVAL_MS)
    );
  };

  const handleStopFueling = async () => {
    setIsFueling(false);
    clearInterval(timerId);
    setTimerId(undefined);
    await fetch(`${fuelingServiceUri}/locations/${locationId}/fuel-pumps/${pumpNumber}/completion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quantity: `${liters.toFixed(2)}`,
        netPricePerLiter: `${NET_PRICE_PER_LITER}`,
      }),
    });
  };

  const handleFailure = async () => {
    setIsFueling(false);
    clearInterval(timerId);
    setTimerId(undefined);
    setLiters(0);
    setShowErrorDialog(true);
    await fetch(`${fuelingServiceUri}/locations/${locationId}/fuel-pumps/${pumpNumber}/failure`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  return (
    <>
      <ApplicationLayout className="bg-lighter">
        <ApplicationLayout.Body className="text-size-h1 padding-x-25 padding-y-15">
          <div className="width-100pct height-100pct display-flex flex-column justify-content-between">
            <Header />
            <div
              className="display-flex flex-column justify-content-center align-items-center width-100pct"
              style={{
                paddingTop: 80,
              }}
            >
              <LitersAndCost liters={liters} cost={cost} />
              <StartStopButton isFueling={isFueling} onStart={handleStartFueling} onStop={handleStopFueling} />
            </div>
            <div className="display-flex justify-content-between align-items-center width-100pct">
              <PricePerLiter price={GROSS_PRICE_PER_LITER} />
              <FailureButton onFailure={handleFailure} />
            </div>
            <FuelingPumpFailureDialog show={showErrorDialog} onClose={() => setShowErrorDialog(false)} />
          </div>
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </>
  );
};
