import React from 'react';
import InfoDialog from '@rio-cloud/rio-uikit/InfoDialog';
import Button from '@rio-cloud/rio-uikit/Button';

type FuelingPumpFailureDialogProps = {
  show: boolean;
  onClose: () => void;
};

export const FuelingPumpFailureDialog: React.FC<FuelingPumpFailureDialogProps> = ({ show = false, onClose }) => {
  return (
    <InfoDialog
      data-testid="failure-dialog"
      aria-label={'support'}
      useOverflow
      show={show}
      className={'display-flex align-items-center'}
      content={
        <div className="text-center">
          <div className="margin-bottom-25">
            <h2 data-testid="failure-dialog-error-message" style={{ fontSize: 50 }}>
              Außer Betrieb
            </h2>
          </div>
          <Button
            data-testid="failure-dialog-confirmation-button"
            className="margin-20 height-60 width-150 btn btn-lg bg-dark wid text-size-h2"
            onClick={onClose}
          >
            OK
          </Button>
        </div>
      }
    />
  );
};
