import { MechanicalDial } from '../MechanicalDial';

type Props = {
  price: number;
};

export const PricePerLiter = ({ price }: Props) => {
  const size = 50;
  return (
    <div>
      <h1 className="text-capitalize padding-left-5">Truck Diesel</h1>
      <h1>
        <MechanicalDial value={price} size={size} />
        <span style={{ fontSize: size - 10, paddingLeft: 10 }}>€</span>
      </h1>
    </div>
  );
};
