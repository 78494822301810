import Button from '@rio-cloud/rio-uikit/Button';

type Props = {
  onFailure?: () => void;
};

export const FailureButton = ({ onFailure = () => {} }: Props) => {
  const handleTriggerFailure = () => {
    onFailure();
  };

  return (
    <h2 style={{ position: 'absolute', right: 30, bottom: 20 }}>
      <Button
        data-testid="failure-button"
        className="btn btn-lg bg-dark text-size-h2 padding-x-50 padding-y-25"
        onClick={handleTriggerFailure}
      >
        Außer Betrieb
      </Button>
    </h2>
  );
};
