import Button from '@rio-cloud/rio-uikit/Button';

type Props = {
  isFueling: boolean;
  onStart?: () => void;
  onStop?: () => void;
};

export const StartStopButton = ({ isFueling, onStart = () => {}, onStop = () => {} }: Props) => {
  const handleToggle = () => {
    if (isFueling) {
      onStop();
    } else {
      onStart();
    }
  };

  return (
    <h1 className="margin-top-40">
      <Button
        data-testid="start-stop-button"
        onClick={handleToggle}
        style={{
          fontSize: 60,
          fontFamily: 'MANEuropeCondensed,"serif"',
          backgroundColor: '#d22b40',
          borderRadius: 6,
          color: 'white',
          marginLeft: 5,
          width: 545,
          paddingTop: 40,
          paddingBottom: 40,
        }}
      >
        {isFueling ? 'Stopp' : 'Start'}
      </Button>
    </h1>
  );
};
